export function validedFormInput(myObj: any, validKeys: string[]) {
  return Object.keys(myObj)
    .filter((key) => validKeys.includes(key))
    .reduce((result, key) => {
      result[key] = myObj[key] === null ? '' : myObj[key];
      return result;
    }, {});
}

// Material-ui only accept empty string and does like null for select or input field.
export function validateInitialValues(myObj: Record<any, any>, valid: Record<string, string>) {
  return Object.keys(myObj)
    .filter((key) => Object.keys(valid).includes(key))
    .reduce((result: any, key) => {
      switch (valid[key]) {
        case 'number':
          result[key] = myObj[key] === null ? null : myObj[key];
          break;
        case 'json':
          result[key] = myObj[key] === null ? null : myObj[key];
          break;
        case 'boolean':
          result[key] = myObj[key] === null ? false : myObj[key];
          break;
        default:
          result[key] = myObj[key] === null ? '' : myObj[key];
          break;
      }
      return result;
    }, {});
}

export function validateSubmitedValues(myObj: any, valid: any): any {
  return Object.keys(myObj)
    .filter((key) => Object.keys(valid).includes(key))
    .reduce((result, key) => {
      switch (valid[key]) {
        case 'string[]':
        case 'json':
        case 'enum':
        case 'date':
        case 'datetime':
          result[key] = myObj[key] ? myObj[key] : null;
          break;
        default:
          result[key] = myObj[key];
          break;
      }
      return result;
    }, {});
}
